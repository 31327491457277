/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-newline */
import { toastClose, toastError, toastLoading, toastSuccess } from "@3edges/utils/dist/toastify";
import { isWebLocalhost } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { COLORS } from "assets/defaultVariables";
import { getStorage, setStorage } from "cache";
import ListPanel from "components/ListPanel";
import ModalDS from "components/ModalDataServer";
import ShowMode from "components/ShowMode";
import { useData } from "contexts/dataContext";
import { LoadingData } from "pages/ServiceAccounts/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import
    {
        podEnum
    } from "types/operation-result-types";
import PanelCard from "ui-components/PanelCard";
import { isAdminOnThisDataserver } from "utils";
import { isAdmin, isSuperAdmin } from "utilsFn";
import { DELETE_DATA_SERVER, GET_ALL_DATA_SERVERS, GET_ALL_DATA_SERVERS_BY_TENANT } from "./gql";
import { ArrowBreadcrumbs, Body, Description, FlexList, Title } from "./styled";
import { TextField } from "@material-ui/core";
import { BodyPanel } from "components/PanelTenant/styled";

function PanelServer (): React.ReactElement {
    const { t } = useTranslation();
    const { tenant } = useData();
    const showAddButton = isAdmin() || isSuperAdmin() || isWebLocalhost()
    const history = useHistory();

    const currUser = getStorage<any>("user");
    const isCurrentAdmin = isAdminOnThisDataserver(tenant?.administrators, currUser)

    const [getListDataServer, { data, loading }] = useLazyQuery(tenant ? GET_ALL_DATA_SERVERS_BY_TENANT : GET_ALL_DATA_SERVERS, { fetchPolicy: "no-cache" });

    useEffect(() => {
        if (tenant) {
            getListDataServer({ variables: { tenantID: tenant._id } })
        } else {
            getListDataServer()
        }
    }, [tenant])

    const [deleteDataServer] = useMutation(DELETE_DATA_SERVER);

    const [dataCollection, setDataCollection] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editDataServerID, setEditDataServerID] = useState<any | null>(null);
    const [isShowModeList, setIsShowModeList] = useState(false);
    const [filterBy, setFilterBy] = useState("");

    useEffect(() => {
        if (data) {
            const items = [...data?.niamDataServers.filter(t => t.name.includes(filterBy))]
            setDataCollection(items)
        }
    }, [filterBy]);

    useEffect(() => {
        if (!loading && data) {
            setDataCollection(data?.niamDataServers)
        }
    }, [data, loading])

    useEffect(() => {
        if (!isModalOpen) {
            setEditDataServerID(null);
        }
    }, [isModalOpen]);

    const onEdit = (value: any): void =>
    {
        setEditDataServerID(value._id);
        setIsModalOpen(true);
    };

    const onShare = (value: any): void => {
        history.push(`/${value._id}/share`);
    }

    const onDelete = (value: any): void =>
    {
        void Swal.fire({
            icon: "error",
            title: t("dataserver:try.to.delete"),
            showConfirmButton: true,
            confirmButtonText: t("dataserver:try.to.delete.yes"),
            showCancelButton: true,
            cancelButtonText: t("dataserver:try.to.delete.no"),
            confirmButtonColor: COLORS.GREEN,
            cancelButtonColor: COLORS.RED
        }).then((result) => {
            if (result.isConfirmed) {
                const toastID = toastLoading()

                void deleteDataServer({
                    variables: {
                        serverID: value._id
                    }
                }).then(({ errors }: any) =>
                {
                    toastClose(toastID)

                    if (errors) {
                        for (const e of errors) {
                            toastError(t(`validations:${e.message}`));
                        }
                        return;
                    }

                    toastSuccess(t("dataserver:message.after.deleted"));
                    setDataCollection(dataCollection.filter(i => i._id !== value._id))
                });
            }
        });
    };

    const onChange = (value: boolean): void => {
        setIsShowModeList(value);
    };

    const onMessageStatus = (params: any): void => {
        switch (params)
        {
            case podEnum.RUNNING:
                toastSuccess(t("serverInfo:startStop.message.running"));
                break;
            case podEnum.STOPPED:
                toastSuccess(t("serverInfo:startStop.message.stopped"));
                break;
            default:
                toastSuccess(t("serverInfo:startStop.message.processing"));
        }
    };

    const onSelect = (value: string): void => {
        setStorage("isShowDashboard", true);
        history.push(`/ds/${value}`);
    };

    return (
        <Body>
            <Title>
                <Link to="/">
                    {t("panel:header.tenants")}
                </Link>

                <ArrowBreadcrumbs />

                {t("panel:header.data.servers")}
            </Title>

            <Description>
                {tenant
                    ? `${t("panel:add.data.server.tenant.selected")}: ${tenant.name}`
                    : t("panel:add.data.server.mode")}
            </Description>

            <BodyPanel>
                <TextField
                        style={{ width: 220 }}
                        label={t("panel:filterBy.label")}
                        value={filterBy}
                        onChange={(e) => {
                            setFilterBy(e.target.value);
                        }}
                    />

                <ShowMode onChange={onChange} />
            </BodyPanel>

            <FlexList>
                {!isShowModeList
                    ? (
                        <>
                            {((showAddButton && tenant && isCurrentAdmin) || !tenant) && (
                                <PanelCard
                                    isAdd
                                    text={t("panel:add.data.server.card")}
                                    onClick={() => {
                                        setIsModalOpen(true);
                                    }}
                                />
                            )}

                            {loading && (
                                <LoadingData>
                                    <div>Loading data</div>
                                    <div className="loader" />
                                </LoadingData>
                            )}

                            {!loading && dataCollection.length > 0 && dataCollection.map((ds) => (
                                <PanelCard
                                    icon="DataServerIcon"
                                    info={ds}
                                    text={ds.name}
                                    description={ds.description}
                                    belongsTo={ds.administrators}
                                    key={`data-server-card-${ds._id}`}
                                    onClick={() => {
                                        onSelect(ds._id);
                                    }}
                                    onEdit={() => {
                                        onEdit(ds);
                                    }}
                                    onShare={() => {
                                        onShare(ds);
                                    }}
                                    onDelete={() => {
                                        onDelete(ds);
                                    }}
                                    onMessageStatus={(params) => {
                                        onMessageStatus(params);
                                    }}
                                />
                            ))}
                        </>
                    )
                    : (
                        <ListPanel t={t} data={dataCollection} loading={loading} onMessageStatus={onMessageStatus} onEdit={onEdit} onDelete={onDelete} onShare={onShare} onSelect={onSelect} parentPage="dataserver">
                            {((showAddButton && tenant) || !tenant) && (
                                <PanelCard
                                    isAdd
                                    smallCard
                                    text={t("panel:add.data.server.card")}
                                    onClick={() => {
                                        setIsModalOpen(true);
                                    }}
                                />
                            )}
                        </ListPanel>
                    )}
            </FlexList>

            <ModalDS
                apiServerID={editDataServerID}
                isModalOpen={isModalOpen}
                tenantID={tenant?._id}
                onClose={(dataSaved?: any, closeModal?: boolean) =>
                {
                    if (dataSaved) {
                        if (closeModal) {
                            // Add new one
                            setDataCollection([dataSaved, ...dataCollection])
                            setIsModalOpen(false)
                        } else {
                            // Overwrite the existent
                            const itemsChanged = dataCollection.map((d) => {
                                if (d._id === dataSaved._id) {
                                    d = dataSaved
                                }

                                return d
                            })

                            setDataCollection([...itemsChanged])
                        }
                    } else {
                        setIsModalOpen(false)
                    }
                }}
            />
        </Body>
    );
}

export default PanelServer;
