/* eslint-disable no-loop-func */
import { toastError, toastSuccess, toastWarning } from "@3edges/utils/dist/toastify";
import { isEmpty, isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MenuItem } from "@material-ui/core";
import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import { setDefaultName } from "components/PrimGraphicalCanvas/d3UseEffects/draw";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, EUpdateType } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useCanvasContext } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import
    {
        FieldsFields,
        NiamFieldTypeEnum,
        rPAddFieldToNodeRelationship as RPAddFieldToNodeRelationship,
        rPNodeRelationshipGet as RPNodeRelationshipGet,
        rPUpdateNodeRelationship as RPUpdateNodeRelationship
    } from "types/operation-result-types";
import { TextField } from "ui-components/styleds";
import RPPropertyBox from "./RpPropertyBox";
import
    {
        RP_ADD_FIELD_TO_NODE_RELATIONSHIP,
        RP_DELETE_NODE_RELATIONSHIP,
        RP_GET_NODE_RELATIONSHIP,
        RP_UPDATE_NODE_RELATIONSHIP
    } from "./gql";
import
    {
        Divider,
        Form,
        LoadingIconStyled,
        RPHeader,
        RPTitle,
        RightPanelAddIconStyled,
        RightPanelDeleteIconStyled,
        RightPanelLoadingStyled,
        SelectFieldStyled
    } from "./styled";

interface RPNodeRelationshipProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

function RPNodeRelationship ({ isOpen, setIsOpen }: RPNodeRelationshipProps): React.ReactElement {
    const { data: dataContext, setData } = useCanvasContext();
    const { server, itemSelected, setItemSelected } = useData();
    const onDelete = useDeleteD3Items();
    const onUpdate = useUpdateD3Items();
    const [getData, { data, loading }] = useLazyQuery<RPNodeRelationshipGet>(RP_GET_NODE_RELATIONSHIP, { fetchPolicy: "no-cache" });

    const [name, setName] = useState<string>(undefined);
    const [description, setDescription] = useState<string>(undefined);
    const [displayName, setDisplayName] = useState<string>(undefined);
    const [objFrom, setObjFrom] = useState<string>(undefined);
    const [objTo, setObjTo] = useState<string>(undefined);
    const [fields, setFields] = useState<FieldsFields[]>([]);

    const { t } = useTranslation();
    const [updateNodeRelationship, { loading: updLoading }] = useMutation<RPUpdateNodeRelationship>(RP_UPDATE_NODE_RELATIONSHIP);
    const [deleteNodeRelationship] = useMutation(RP_DELETE_NODE_RELATIONSHIP);
    const [addFieldToNodeRelationship] = useMutation<RPAddFieldToNodeRelationship>(RP_ADD_FIELD_TO_NODE_RELATIONSHIP);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { nodeRelationshipID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    useEffect(() => {
        if (data && !loading) {
            setName(data.niamNodeRelationship.name);
            setDescription(data.niamNodeRelationship.description);
            setDisplayName(data.niamNodeRelationship.displayName);
            setFields(data.niamNodeRelationship.fields as FieldsFields[]);
            setObjFrom(data.niamNodeRelationship.relation.objFrom?._id);
            setObjTo(data.niamNodeRelationship.relation.objTo?._id);
        }
    }, [loading, data]);

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <Form>
                <TextField
                    data-cy="rpNRPage_name"
                    label={t("rightPanel:nodeRelationship.input.name.label")}
                    onChange={(e) => {
                        setName(e.target.value);
                        setDisplayName(e.target.value);
                        turnToUnsaved();
                    }}
                    value={displayName || ""}
                />

                <TextField
                    data-cy="rpNRPage_description"
                    label={t("rightPanel:nodeRelationship.input.description.label")}
                    inputProps={{ maxLength: 2000 }}
                    onChange={(e) => {
                        if (e.target.value.length === 2000 ) {
                            toastWarning(t("rightPanel:description.length.warning"));
                        }
                        setDescription(e.target.value);
                        turnToUnsaved();
                    }}
                    value={description || ""}
                />

                <Divider />

                <SelectFieldStyled
                    value={objFrom || ""}
                    label={t("rightPanel:property.input.objFrom.label")}
                    onChange={(event) => {
                        setObjFrom(event.target.value);
                        turnToUnsaved();
                    }}
                >
                    {dataContext.objects.map((obj) => (
                        <MenuItem key={`objectFromType-${obj._id}`} value={obj._id}>
                            {obj.displayName}
                        </MenuItem>
                    ))}
                </SelectFieldStyled>

                <SelectFieldStyled
                    value={objTo || ""}
                    label={t("rightPanel:property.input.objTo.label")}
                    onChange={(event) => {
                        setObjTo(event.target.value);
                        turnToUnsaved();
                    }}
                >
                    {dataContext.objects.map((obj) => (
                        <MenuItem key={`objectToType-${obj._id}`} value={obj._id}>
                            {obj.displayName}
                        </MenuItem>
                    ))}
                </SelectFieldStyled>

                <Divider />

                <RPHeader>
                    <RPTitle>
                        Properties
                    </RPTitle>

                    <RightPanelAddIconStyled
                        onClick={() => {
                            const { defaultName } = setDefaultName(`Field`, null, fields);

                            void addFieldToNodeRelationship({
                                variables: {
                                    nodeRelationshipID: itemSelected.data._id,
                                    newNiamFieldInput: {
                                        name: defaultName,
                                        displayName: defaultName,
                                        fieldType: NiamFieldTypeEnum.String,
                                        isArray: false,
                                        isNaming: false,
                                        isSecret: false,
                                        isDisplay: false,
                                        isRequired: false
                                    }
                                }
                            }).then((res) => {
                                setFields([...fields, res.data.addNewFieldToNiamNodeRelationship]);

                                let newDataNodeRelationships = [...dataContext.nodeRelationships]

                                newDataNodeRelationships = newDataNodeRelationships.map(item => {
                                    if (item._id === itemSelected.data._id) {
                                        item.fields.push(res.data.addNewFieldToNiamNodeRelationship)
                                    }

                                    return item
                                })

                                const newData = {
                                    ...dataContext,
                                    nodeRelationships: newDataNodeRelationships
                                }

                                setData(newData)
                            });
                        }}
                    />
                </RPHeader>

                {fields.map((field) => {
                    if (field.fieldType === "Relationship") {
                        return null;
                    }
                    return (
                        <RPPropertyBox
                            canDelete
                            field={field}
                            key={`NodeRelationshipField_${itemSelected.data._id}_${field._id}`}
                            orbitData={{
                                __typename: itemSelected.data.__typename,
                                _id: itemSelected.data._id,
                                displayName: itemSelected.data.displayName,
                                type: "NodeRelationship",
                                x: 0,
                                y: 0
                            }}
                            refetch={({ id }) => {
                                const nFields = [...fields.filter((f) => f._id !== id)];

                                setFields(nFields);
                            }}
                        />
                    );
                })}

                <Divider />

                <ButtonListFlex $justifyContent="space-between">
                    <RightPanelDeleteIconStyled
                        onClick={() => {
                            setIsOpen(false);

                            deleteNodeRelationship({
                                variables: {
                                    nodeRelationshipID: data.niamNodeRelationship._id
                                }
                            }).then(({ errors }: any) => {
                                if (errors) {
                                    for (const e of errors) {
                                        toastError(t(`validations:${e.message}`));
                                    }
                                    return;
                                }

                                onDelete({
                                    id: data.niamNodeRelationship._id,
                                    type: EDeleteType.NODE_RELATIONSHIP
                                });
                            });
                        }}
                    />

                    <ConfirmButton
                        data-cy="rpNRPage_btnSave"
                        disabled={isOpen && !itemSelected.hasSaved ? false : true}
                        onClick={() => {
                            if (isEmpty(objFrom) && isNotEmpty(objTo)) {
                                toastWarning(t("nodeRelationship:TARGET_WITHOUT_SOURCE"));
                                return;
                            }

                            if (isEmpty(objTo) && isNotEmpty(objFrom)) {
                                toastWarning(t("nodeRelationship:SOURCE_WITHOUT_TARGET"));
                                return;
                            }

                            if (isNotEmpty(objTo) && isNotEmpty(objFrom) && objTo === objFrom) {
                                toastWarning(t("nodeRelationship:SAME_OBJECT"));
                                return;
                            }

                            const nValueFrom = dataContext.objects.find((obj) => obj._id === objFrom);

                            const nValueTo = dataContext.objects.find((obj) => obj._id === objTo);

                            const objFromID = nValueFrom
                                ? nValueFrom.item._id
                                : null;
                            const objFromName = nValueFrom
                                ? nValueFrom.item.name
                                : null;
                            const objToID = nValueTo
                                ? nValueTo.item._id
                                : null;
                            const objToName = nValueTo
                                ? nValueTo.item.name
                                : null;

                            updateNodeRelationship({
                                variables: {
                                    serverID: server._id,
                                    nodeRelationshipID: data.niamNodeRelationship._id,
                                    params: {
                                        displayName,
                                        name,
                                        description
                                    },
                                    objFrom: objFromID,
                                    objTo: objToID,
                                    objFromName,
                                    objToName
                                }
                            }).then(({ errors, data: dataUpdated }: any) =>
                            {
                                if (errors) {
                                    for (const e of errors)
                                    {
                                        if (e.message.includes("NAME_ALREADY_IN_USE"))
                                        {
                                            toastError(t(`validations:${e.message}`) + displayName);
                                            setName(itemSelected.data.displayName)
                                        } else
                                        {
                                            toastError(t(`validations:${e.message}`));
                                        }
                                    }

                                    return;
                                }

                                onUpdate({
                                    id: data.niamNodeRelationship._id,
                                    type: EUpdateType.NODE_RELATIONSHIP,
                                    newData: dataUpdated.updateNiamNodeRelationship,
                                    rPData: itemSelected.data
                                });

                                setName(dataUpdated.updateNiamNodeRelationship.name);
                                setDescription(dataUpdated.updateNiamNodeRelationship.description);
                                setDisplayName(dataUpdated.updateNiamNodeRelationship.displayName);
                                setFields(dataUpdated.updateNiamNodeRelationship.fields as FieldsFields[]);
                                setObjFrom(dataUpdated.updateNiamNodeRelationship.relation.objFrom?._id);
                                setObjTo(dataUpdated.updateNiamNodeRelationship.relation.objTo?._id);

                                itemSelected.data.displayName = dataUpdated.updateNiamNodeRelationship.displayName
                                setItemSelected({ ...itemSelected, hasSaved: true })
                                toastSuccess(t("rightPanel:alert.save.success"));
                            });
                        }}
                    >
                        <div>Save</div>
                    </ConfirmButton>

                </ButtonListFlex>
            </Form>
        </>
    );
}

export default RPNodeRelationship;
