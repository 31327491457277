/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-lines */
/* eslint-disable no-loop-func */
import { toastError, toastWarning } from "@3edges/utils/dist/toastify";
import { useMutation } from "@apollo/client";
import { useData } from "contexts/dataContext";
import { ContextMenuContext } from "contexts/rightClickContext";
import { GraphQLError } from "graphql";
import React, { useContext } from "react";
import * as d3 from "d3";
import { useTranslation } from "react-i18next";
import
    {
        NiamFieldTypeEnum,
        addActionAuthZLabelToNode,
        addActionAuthZLabelToRelationship,
        addNewDefaultPrimNodeRelationship, addNewNiamCustomMutation, addNewNiamEnum, addNewNiamInterfaceToNiamDataServer,
        addNewNiamObjectToServer, addNewNiamScriptToServer, addNewNiamTagForNiamDataServer, addObjectAuthZLabelToNode,
        addSimplePathAuthZLabelToRelationship, addSimplePathCreateAuthZLabelToRelationship, addSimplePathDeleteAuthZLabelToRelationship,
        addSimplePathReadAuthZLabelToRelationship, addSimplePathUpdateAuthZLabelToRelationship, addSubjectAuthZLabelToNode, getPrimGInfo_niamDataServer_niamObjects,
        getPrimGInfo_niamDataServer_niamObjects_fields
    } from "types/operation-result-types";
import { useCanvasContext } from "../../../contexts/canvasContext";
import definePolicy from "../LeftPanelDragHandlersHook/definePolicy";
import { useAddD3Items } from "../addD3Items/useAddD3Items";
import { setDefaultName } from "../d3UseEffects/draw";
import
    {
        ADD_NEW_DEFAULT_ENUM,
        ADD_NEW_DEFAULT_PRIM_CUSTOM_MUTATION,
        ADD_NEW_DEFAULT_PRIM_INTERFACE, ADD_NEW_DEFAULT_PRIM_NODE_RELATIONSHIP, ADD_NEW_DEFAULT_PRIM_OBJECT, ADD_NEW_DEFAULT_PRIM_SCRIPT,
        ADD_NEW_DEFAULT_PRIM_TAG
    } from "../gql";
import { MenuOptionsType } from "../types";
import
    {
        ADD_NEW_ACTION, ADD_NEW_ACTION_RELATIONSHIP,
        ADD_NEW_AND_OPERATOR,
        ADD_NEW_CLAIM, ADD_NEW_HOLDER,
        ADD_NEW_ISSUER,
        ADD_NEW_OR_OPERATOR,
        ADD_NEW_PATH, ADD_NEW_PATH_CREATE,
        ADD_NEW_PATH_DELETE, ADD_NEW_PATH_READ, ADD_NEW_PATH_UPDATE, ADD_NEW_RESOURCE, ADD_NEW_SUBJECT, ADD_NEW_VC, ADD_NEW_VC_RELATIONSHIP
    } from "./gql";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type AddNewObjectReturnType = ({ x, y }: { x: number; y: number; }) => void;

// eslint-disable-next-line @typescript-eslint/no-type-alias
type AddNewTargetLinkReturnType = (targetData:  getPrimGInfo_niamDataServer_niamObjects_fields) => void;

// eslint-disable-next-line @typescript-eslint/no-type-alias
type AddNewTargetOrbitReturnType = (targetData: getPrimGInfo_niamDataServer_niamObjects) => void;

export const useAddNewNiamObject = (): AddNewObjectReturnType => {
    const { t } = useTranslation();
    const { data } = useCanvasContext();
    const { server, setItemSelected, zoom } = useData();
    const addD3Item = useAddD3Items();
    const [addNewNiamObject] = useMutation<addNewNiamObjectToServer>(ADD_NEW_DEFAULT_PRIM_OBJECT);

    return ({ x, y }) =>
    {
        setItemSelected(null)
        const { defaultName } = setDefaultName(`Object`, null, data.objects);

        void addNewNiamObject({
            variables: {
                serverID: server._id,
                name: defaultName,
                displayName: defaultName,
                x,
                y
            }
        }).then(({ data: res, errors }: { data?: addNewNiamObjectToServer; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const content = { ...res.addNewNiamObjectToServer }

            addD3Item({
                type: "object",
                newItemData: {
                    ...content,
                    fields: [],
                    nodeRelationships: [],
                    vx: 0,
                    vy: 0,
                    isShown: true,
                    item: { ...content }
                }
            });
        });
    };
};

export const useAddNewNiamInterface = (): AddNewObjectReturnType => {
    const { t } = useTranslation();
    const { server, zoom } = useData();
    const { data } = useCanvasContext();
    const addD3Item = useAddD3Items();
    const [addNewNiamInterface] = useMutation<addNewNiamInterfaceToNiamDataServer>(ADD_NEW_DEFAULT_PRIM_INTERFACE);

    return ({ x, y }) => {
        const { defaultName } = setDefaultName(`Interface`, null, data.interfaces);

        void addNewNiamInterface({
            variables: {
                serverID: server._id,
                name: defaultName,
                displayName: defaultName,
                x,
                y
            }
        }).then(({ data: res, errors }: { data?: addNewNiamInterfaceToNiamDataServer; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const content = { ...res.addNewNiamInterfaceToNiamDataServer }

            void addD3Item({
                type: "interface",
                newItemData: {
                    ...content,
                    vx: 0,
                    vy: 0,
                    isShown: true
                }
            });
        });
    };
};

export const useAddNewNiamScript = (): AddNewObjectReturnType => {
    const { t } = useTranslation();
    const { server, zoom } = useData();
    const { data } = useCanvasContext();
    const addD3Item = useAddD3Items();
    const [addNewNiamScript] = useMutation<addNewNiamScriptToServer>(ADD_NEW_DEFAULT_PRIM_SCRIPT);

    return ({ x, y }) => {
        const { defaultName } = setDefaultName(`Script`, null, data.scripts);

        void addNewNiamScript({
            variables: {
                serverID: server._id,
                name: defaultName,
                displayName: defaultName,
                x,
                y
            }
        }).then(({ data: res, errors }: { data?: addNewNiamScriptToServer; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const content = { ...res.addNewNiamScriptToServer }

            void addD3Item({
                type: "script",
                newItemData: {
                    ...content,
                    vx: 0,
                    vy: 0,
                    isShown: true
                }
            });
        });
    };
};

export const useAddNewNiamCustomMutation = (): AddNewObjectReturnType => {
    const { t } = useTranslation();
    const { server, zoom } = useData();
    const { data } = useCanvasContext();
    const addD3Item = useAddD3Items();
    const [addNewNiamCustomMutation] = useMutation<addNewNiamCustomMutation>(ADD_NEW_DEFAULT_PRIM_CUSTOM_MUTATION);

    return ({ x, y }) => {
        const { defaultName } = setDefaultName("CM", null, data.customMutations);

        void addNewNiamCustomMutation({
            variables: {
                serverID: server._id,
                name: defaultName,
                displayName: defaultName,
                x,
                y
            }
        }).then(({ data: res, errors }: { data?: addNewNiamCustomMutation; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const content = { ...res.addNewNiamCustomMutation }

            void addD3Item({
                type: "customMutation",
                newItemData: {
                    ...content,
                    vx: 0,
                    vy: 0,
                    isShown: true
                }
            });
        });
    };
};

export const useAddNewNiamTag = (): AddNewObjectReturnType => {
    const { t } = useTranslation();
    const { server, zoom } = useData();
    const { data } = useCanvasContext();
    const addD3Item = useAddD3Items();
    const [addNewNiamTag] = useMutation<addNewNiamTagForNiamDataServer>(ADD_NEW_DEFAULT_PRIM_TAG);

    return ({ x, y }) => {
        const { defaultName } = setDefaultName(`Tag`, null, data.tags);

        void addNewNiamTag({
            variables: {
                serverID: server._id,
                name: defaultName,
                displayName: defaultName,
                x,
                y
            }
        }).then(({ data: res, errors }: { data?: addNewNiamTagForNiamDataServer; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const content = { ...res.addNewNiamTagForNiamDataServer };

            void addD3Item({
                type: "tag",
                newItemData: {
                    ...content,
                    vx: 0,
                    vy: 0,
                    isShown: true
                }
            });
        });
    };
};

export const useAddNewEnum = (): AddNewObjectReturnType => {
    const { t } = useTranslation();
    const { server, zoom } = useData();
    const { data } = useCanvasContext();
    const addD3Item = useAddD3Items();
    const [addNewNiamEnum] = useMutation<addNewNiamEnum>(ADD_NEW_DEFAULT_ENUM);

    return ({ x, y }) => {
        const { defaultName } = setDefaultName(`Enum`, null, data.enums);

        void addNewNiamEnum({
            variables: {
                serverID: server._id,
                name: defaultName,
                displayName: defaultName,
                x,
                y,
            }
        }).then(({ data: res, errors }: { data?: addNewNiamEnum; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const content = { ...res.addNewNiamEnum };

            void addD3Item({
                type: "enum",
                newItemData: {
                    ...content,
                    vx: 0,
                    vy: 0,
                    fieldType: NiamFieldTypeEnum.NiamEnum,
                    isShown: true
                }
            });
        });
    };
};

export const useAddNewNiamNodeRelationship = (): AddNewObjectReturnType => {
    const { t } = useTranslation();
    const { server, zoom } = useData();
    const { data } = useCanvasContext();
    const addD3Item = useAddD3Items();
    const [addNewNiamNodeRelationship] = useMutation<addNewDefaultPrimNodeRelationship>(ADD_NEW_DEFAULT_PRIM_NODE_RELATIONSHIP);

    return ({ x, y }) => {
        const { defaultName } = setDefaultName(`NR`, null, data.nodeRelationships);

        void addNewNiamNodeRelationship({
            variables: {
                serverID: server._id,
                name: defaultName,
                displayName: defaultName,
                x,
                y
            }
        }).then(({ data: res, errors }: { data?: addNewDefaultPrimNodeRelationship; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const content = { ...res.addNewDefaultPrimNodeRelationship }

            void addD3Item({
                type: "nodeRelationship",
                newItemData: {
                    ...content.rel,
                    fieldType: NiamFieldTypeEnum.NodeRelationship,
                    vx: 0,
                    vy: 0,
                    isShown: true
                }
            });
        });
    };
};

export const useAddNewPath = (): AddNewTargetLinkReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addPath] = useMutation<addSimplePathAuthZLabelToRelationship>(ADD_NEW_PATH);

    return (targetData) => {
        void addPath({
            variables: {
                targetID: targetData._id,
            }
        }).then(({ data: res, errors }: { data?: addSimplePathAuthZLabelToRelationship, errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addSimplePathAuthZLabelToRelationship._id,
                    relatedTo: targetData._id
                },
                k: "isPath"
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewPathCreate = (): AddNewTargetLinkReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addPathCreate] = useMutation<addSimplePathCreateAuthZLabelToRelationship>(ADD_NEW_PATH_CREATE);

    return (targetData) => {
        void addPathCreate({
            variables: {
                targetID: targetData._id,
            }
        }).then(({ data: res, errors }: { data?: addSimplePathCreateAuthZLabelToRelationship, errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addSimplePathCreateAuthZLabelToRelationship._id,
                    relatedTo: targetData._id
                },
                k: "isPathCreate"
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewPathRead = (): AddNewTargetLinkReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addPathRead] = useMutation<addSimplePathReadAuthZLabelToRelationship>(ADD_NEW_PATH_READ);

    return (targetData) => {
        void addPathRead({
            variables: {
                targetID: targetData._id,
            }
        }).then(({ data: res, errors }: { data?: addSimplePathReadAuthZLabelToRelationship, errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addSimplePathReadAuthZLabelToRelationship._id,
                    relatedTo: targetData._id
                },
                k: "isPathRead"
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewPathUpdate = (): AddNewTargetLinkReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addPathUpdate] = useMutation<addSimplePathUpdateAuthZLabelToRelationship>(ADD_NEW_PATH_UPDATE);

    return (targetData) => {
        void addPathUpdate({
            variables: {
                targetID: targetData._id,
            }
        }).then(({ data: res, errors }: { data?: addSimplePathUpdateAuthZLabelToRelationship, errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addSimplePathUpdateAuthZLabelToRelationship._id,
                    relatedTo: targetData._id
                },
                k: "isPathUpdate"
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewPathDelete = (): AddNewTargetLinkReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addPathDelete] = useMutation<addSimplePathDeleteAuthZLabelToRelationship>(ADD_NEW_PATH_DELETE);

    return (targetData) => {
        void addPathDelete({
            variables: {
                targetID: targetData._id,
            }
        }).then(({ data: res, errors }: { data?: addSimplePathDeleteAuthZLabelToRelationship, errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addSimplePathDeleteAuthZLabelToRelationship._id,
                    relatedTo: targetData._id
                },
                k: "isPathDelete"
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewAction = (): AddNewTargetOrbitReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addAction] = useMutation<addActionAuthZLabelToNode>(ADD_NEW_ACTION);

    return (targetData) => {
        void addAction({
            variables: {
                targetID: targetData._id
            }
        }).then(({ data: res, errors }: { data?: addActionAuthZLabelToNode; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addActionAuthZLabelToNode["_id"],
                    relatedTo: targetData._id
                },
                k: "isAction",
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewVC = (): AddNewTargetOrbitReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addVC] = useMutation(ADD_NEW_VC);

    return (targetData) => {
        void addVC({
            variables: {
                targetID: targetData._id
            }
        }).then(({ data: res, errors }: { data?: any; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addVCAuthZLabelToNode["_id"],
                    relatedTo: targetData._id
                },
                k: "isVC",
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewHolder = (): AddNewTargetOrbitReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addHolder] = useMutation(ADD_NEW_HOLDER);

    return (targetData) => {
        void addHolder({
            variables: {
                targetID: targetData._id
            }
        }).then(({ data: res, errors }: { data?: any; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addHolderAuthZLabelToNode["_id"],
                    relatedTo: targetData._id
                },
                k: "isHolder",
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewAndOperator = (): AddNewTargetOrbitReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addAndOperator] = useMutation(ADD_NEW_AND_OPERATOR);

    return (targetData) => {
        void addAndOperator({
            variables: {
                targetID: targetData._id
            }
        }).then(({ data: res, errors }: { data?: any; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addAndOperatorAuthZLabelToNode["_id"],
                    relatedTo: targetData._id
                },
                k: "isAndOperator",
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewOrOperator = (): AddNewTargetOrbitReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addOrOperator] = useMutation(ADD_NEW_OR_OPERATOR);

    return (targetData) => {
        void addOrOperator({
            variables: {
                targetID: targetData._id
            }
        }).then(({ data: res, errors }: { data?: any; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addOrOperatorAuthZLabelToNode["_id"],
                    relatedTo: targetData._id
                },
                k: "isOrOperator",
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewIssuer = (): AddNewTargetOrbitReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addIssuer] = useMutation(ADD_NEW_ISSUER);

    return (targetData) => {
        void addIssuer({
            variables: {
                targetID: targetData._id
            }
        }).then(({ data: res, errors }: { data?: any; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addIssuerAuthZLabelToNode["_id"],
                    relatedTo: targetData._id
                },
                k: "isIssuer",
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewClaim = (): AddNewTargetOrbitReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addClaim] = useMutation(ADD_NEW_CLAIM);

    return (targetData) => {
        void addClaim({
            variables: {
                targetID: targetData._id
            }
        }).then(({ data: res, errors }: { data?: any; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addClaimAuthZLabelToNode["_id"],
                    relatedTo: targetData._id
                },
                k: "isClaim",
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewActionRelationship = (): AddNewTargetLinkReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addAction] = useMutation<addActionAuthZLabelToRelationship>(ADD_NEW_ACTION_RELATIONSHIP);

    return (targetData) => {
        void addAction({
            variables: {
                targetID: targetData._id,
            }
        }).then(({ data: res, errors }: { data?: addActionAuthZLabelToRelationship, errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addActionAuthZLabelToRelationship._id,
                    relatedTo: targetData._id
                },
                k: "isAction"
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewVCRelationship = (): AddNewTargetLinkReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addVC] = useMutation(ADD_NEW_VC_RELATIONSHIP);

    return (targetData) => {
        void addVC({
            variables: {
                targetID: targetData._id,
            }
        }).then(({ data: res, errors }: { data?: any, errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addVCAuthZLabelToRelationship._id,
                    relatedTo: targetData._id
                },
                k: "isVC"
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewResource = (): AddNewTargetOrbitReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addResource] = useMutation<addObjectAuthZLabelToNode>(ADD_NEW_RESOURCE);

    return (targetData) => {
        void addResource({
            variables: {
                targetID: targetData._id
            }
        }).then(({ data: res, errors }: { data?: addObjectAuthZLabelToNode; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addObjectAuthZLabelToNode["_id"],
                    relatedTo: targetData._id
                },
                k: "isResource",
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useAddNewSubject = (): AddNewTargetOrbitReturnType => {
    const { d3Data, data, setData, enters } = useCanvasContext();

    const { t } = useTranslation();
    const [addSubject] = useMutation<addSubjectAuthZLabelToNode>(ADD_NEW_SUBJECT);

    return (targetData) => {
        const alreadyExists = data.objects.find(obj => obj.isSubject)

        if (alreadyExists) {
            toastWarning(t(`validations:addSubject.already.exists`));
            return
        }

        void addSubject({
            variables: {
                targetID: targetData._id
            }
        }).then(({ data: res, errors }: { data?: addSubjectAuthZLabelToNode; errors?: readonly GraphQLError[] }) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            const nValue = {
                data: {
                    _id: res.addSubjectAuthZLabelToNode._id,
                    relatedTo: targetData._id
                },
                k: "isSubject",
            }

            definePolicy(d3Data, data, nValue, enters, setData);
        });
    }
};

export const useOnPageRightClick = (isShowDashboard?: boolean): ((e: React.MouseEvent) => void) => {
    const contextMenuState = useContext(ContextMenuContext);
    const { t } = useTranslation();
    const addNewNiamObject = useAddNewNiamObject();
    const addNewNiamInterface = useAddNewNiamInterface();
    const addNewNiamScript = useAddNewNiamScript();
    const addNewNiamCustomMutation = useAddNewNiamCustomMutation();
    const addNewNiamNodeRelationship = useAddNewNiamNodeRelationship();
    const addNewNiamTag = useAddNewNiamTag();
    const addNewEnum = useAddNewEnum();
    const { container, d3Data } = useCanvasContext();

    return (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const [mouseX, mouseY] = d3.pointer(e, d3Data.g.node());

        const menuOptions = [
                {
                    key: "pageRightClickItemObject",
                    image: "ObjectIcon",
                    title: t("rightClick:item.object"),
                    type: MenuOptionsType.ADD,
                    onClick: (ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();

                        addNewNiamObject({ x: mouseX, y: mouseY });
                        contextMenuState.setContextMenuState({
                            visibility: false
                        });
                    }
                },
                {
                    key: "pageRightClickItemInterface",
                    image: "InterfaceIcon",
                    title: t("rightClick:item.interface"),
                    type: MenuOptionsType.ADD,
                    onClick: (ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();

                        addNewNiamInterface({ x: mouseX, y: mouseY });
                        contextMenuState.setContextMenuState({
                            visibility: false
                        });
                    }
                },
                {
                    key: "pageRightClickItemScript",
                    image: "ScriptIcon",
                    title: t("rightClick:item.script"),
                    type: MenuOptionsType.ADD,
                    onClick: (ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();

                        addNewNiamScript({ x: mouseX, y: mouseY });
                        contextMenuState.setContextMenuState({
                            visibility: false
                        });
                    }
                },
                {
                    key: "pageRightClickItemCustomMutation",
                    image: "CustomMutationIcon",
                    title: t("rightClick:item.customMutation"),
                    type: MenuOptionsType.ADD,
                    onClick: (ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();

                        addNewNiamCustomMutation({ x: mouseX, y: mouseY });
                        contextMenuState.setContextMenuState({
                            visibility: false
                        });
                    }
                },
                {
                    key: "pageRightClickItemTag",
                    image: "TagIcon",
                    title: t("rightClick:item.tag"),
                    type: MenuOptionsType.ADD,
                    onClick: (event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        addNewNiamTag({ x: mouseX, y: mouseY });
                        contextMenuState.setContextMenuState({
                            visibility: false
                        });
                    }
                },
                {
                    key: "pageRightClickItemNodeRelationship",
                    image: "NodeRelationshipIcon",
                    title: t("rightClick:item.nodeRelationship"),
                    type: MenuOptionsType.ADD,
                    onClick: (ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();

                        addNewNiamNodeRelationship({ x: mouseX, y: mouseY });
                        contextMenuState.setContextMenuState({
                            visibility: false
                        });
                    }
                },
                {
                    key: "pageRightClickItemEnum",
                    image: "EnumIcon",
                    title: t("rightClick:item.enum"),
                    type: MenuOptionsType.ADD,
                    onClick: (event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        addNewEnum({ x: mouseX, y: mouseY });
                        contextMenuState.setContextMenuState({
                            visibility: false
                        });
                    }
                }
            ]

        if (isShowDashboard) {
            contextMenuState.setContextMenuState({
                visibility: true,
                x: e.pageX,
                y: e.pageY,
                menuOptions,
                title: t("rightClick:manage.item")
            });
        }
    };
};

