import styled from "styled-components";

export const Header = styled.div`
    width: 100vw;
    height: 64px;
    padding: 12px 213px 12px 120px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.background};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: padding 0.3s ease;

    @media (max-width: 1000px) {
        padding: 10px;
    }
`;

export const Logo = styled.p`
    cursor: pointer;
    height: 25px;
    font-family: IBM Plex Sans Condensed;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 4px;
    text-align: left;
    color: ${({ theme }) => theme.colors.fontNormal};
    margin: 5px;
`;

export const OpenedProfileItemIcon = styled.img.attrs((props: { src: string }) => ({
    src: props.src
}))`
    width: 24px;
    height: 24px;

    box-sizing: border-box;
    object-fit: contain;
`;
