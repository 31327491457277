/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable no-loop-func */
import { toastError, toastWarning } from "@3edges/utils/dist/toastify";
import { isEmpty } from "@3edges/utils/dist/utils";
import { useMutation } from "@apollo/client";
import { getStorage } from "cache";
import { useData } from "contexts/dataContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NiamFieldTypeEnum } from "types/operation-result-types";
import { setCurve } from "utils";
import { useCanvasContext } from "../../../contexts/canvasContext";
import
    {
        D3_ADD_PATH_OBJECT_TO_CUSTOM_MUTATION,
        D3_ADD_PROPERTY_TO_INTERFACE,
        D3_ADD_PROPERTY_TO_OBJECT,
        D3_ADD_PROPERTY_TO_SCRIPT,
        D3_IMPLEMENT_INTERFACE
    } from "../RightClickHandlers/gql";
import { useAddD3Items } from "../addD3Items/useAddD3Items";
import { NodeTypename, RelationshipType } from "../types";

export const setDefaultName = (originName, targetName = null, orbitField, targetData = null): { defaultName: string, counter: number } => {
    let haveFoundName = false;
    let counter = 1;
    let defaultName = "";

    while (!haveFoundName) {
        defaultName = isEmpty(targetName) ? `${originName}_${counter}` : `${originName}_${counter}_${targetName}`;

        const alreadyExists = orbitField.some((item) =>
        {
            try {
                return item.relation.rel.name === defaultName
            } catch {
                try {
                    return item.rel.name === defaultName
                } catch {
                    if (targetData && targetData.data)
                    {
                        return item.name === `${defaultName}_${targetData.data.name}` || `${item.name}_${targetData.data.name}` === `${defaultName}_${targetData.data.name}`
                    }
                    return item.name === defaultName
                }
            }
        });

        if (alreadyExists) {
            counter++;
        } else {
            haveFoundName = true;
        }
    }

    return { defaultName, counter };
}

export const useDraw = (): void => {
    const addD3Item = useAddD3Items();
    const { t } = useTranslation();
    const { server, setItemSelected, targetData, sourceData, setSourceData, setTargetData, isDrawing } = useData();
    const { data: dataContext } = useCanvasContext();

    const [implementInterface] = useMutation(D3_IMPLEMENT_INTERFACE);
    const [addNewPropertyToObjectMutation] = useMutation<{ addNewFieldToNiamObject: { _id: string }; }>(D3_ADD_PROPERTY_TO_OBJECT);
    const [addNewPathObjectToCustomMutation] = useMutation<{ addNewPathObjectToCustomMutation: { _id: string }; }>(D3_ADD_PATH_OBJECT_TO_CUSTOM_MUTATION);
    const [addNewPropertyToScriptMutation] = useMutation<{ addNewRelationshipToNiamScript: { _id: string }; }>(D3_ADD_PROPERTY_TO_SCRIPT);
    const [addNewPropertyToIntefaceMutation] = useMutation(D3_ADD_PROPERTY_TO_INTERFACE);

    // eslint-disable-next-line complexity
    useEffect(() => {
        const isShowDashboard = getStorage("isShowDashboard");

        if (!isDrawing && targetData && sourceData) {
            const sourceTypename = sourceData.data?.__typename;
            const targetTypename = targetData.data?.__typename;

            if (!isShowDashboard) {
                if (sourceTypename === NodeTypename.NIAM_OBJECT && targetTypename === NodeTypename.NIAM_CUSTOM_MUTATION) {

                    const objParent = dataContext.objects.find(item => item._id === sourceData.data._id)
                    const cmParent = dataContext.customMutations.find(item => item._id === targetData.data._id)

                    void addNewPathObjectToCustomMutation({
                        variables: {
                            objectID: objParent._id,
                            targetID: cmParent._id
                        }
                    }).then(({ data, errors }: any) => {
                        if (errors) {
                            for (const e of errors) {
                                toastError(t(`validations:${e.message}`));
                            }

                            return;
                        }

                        void addD3Item({
                            type: RelationshipType.PATHS,
                            newItemData: {
                                source: cmParent,
                                target: objParent,
                                type: RelationshipType.PATHS,
                                _id: data.addNewPathObjectToCustomMutation._id,
                                isShown: true
                            }
                        });
                    });

                    setItemSelected(null);
                    setSourceData(undefined);
                    setTargetData(undefined);
                }
            }

            if (
                isShowDashboard &&
                ![NodeTypename.NIAM_ENUM, NodeTypename.NIAM_TAG, NodeTypename.NIAM_SCRIPT, NodeTypename.NIAM_CUSTOM_MUTATION].includes(targetTypename as NodeTypename)
            ) {
                if (sourceTypename === NodeTypename.NIAM_OBJECT && (targetTypename !== NodeTypename.NIAM_NODE_RELATIONSHIP && targetTypename !== NodeTypename.NIAM_INTERFACE)) {
                    const objParent = dataContext.objects.find(item => item._id === sourceData.data._id)
                    const { defaultName } = setDefaultName(objParent.name, targetData.data.name, objParent.fields, targetData);

                    let counter = 1;
                    targetData.data.fields.forEach((field) => {
                        if (field.relatedTo === objParent._id) {
                            counter++;
                        }
                    });

                    objParent.fields.forEach((field) => {
                        if (field.relatedTo === targetData.data._id) {
                            counter++;
                        }
                    });

                    void addNewPropertyToObjectMutation({
                        variables: {
                            NiamObjectID: objParent._id,
                            newNiamFieldInput: {
                                fieldType:
                                    targetTypename === NodeTypename.NIAM_NODE_RELATIONSHIP
                                        ? NiamFieldTypeEnum.NodeRelationship
                                        : NiamFieldTypeEnum.Relationship,
                                name: defaultName,
                                displayName: defaultName,
                                isArray: false,
                                isRequired: false,
                                isNaming: false,
                                isSecret: false,
                                relatedTo: targetData.data._id
                            }
                        }
                    }).then(({ data, errors }: any) => {
                        if (errors) {
                            for (const e of errors) {
                                toastError(t(`validations:${e.message}`));
                            }

                            return;
                        }

                        void addD3Item({
                            type: RelationshipType.RELATION,
                            newItemData: {
                                curve: setCurve(counter),
                                source: objParent._id,
                                target: targetData.data._id,
                                _id: data.addNewFieldToNiamObject._id,
                                fieldType: NiamFieldTypeEnum.Relationship,
                                isShown: true,
                                displayName: defaultName,
                                name: defaultName,
                                relatedTo: targetData.data._id
                            }
                        });
                    });
                }

                if (
                    sourceTypename === NodeTypename.NIAM_SCRIPT &&
                    (targetTypename === NodeTypename.NIAM_OBJECT || targetTypename === NodeTypename.NIAM_NODE_RELATIONSHIP)
                ) {
                    const scriptParent = dataContext.scripts.find(item => item._id === sourceData.data._id)
                    const { defaultName } = setDefaultName(scriptParent.name, targetData.data.name, scriptParent.relationships, targetData);

                    let targetParent;

                    if (targetTypename === NodeTypename.NIAM_OBJECT) {
                        targetParent = dataContext.objects.find(item => item._id === targetData.data._id)
                    }

                    if (targetTypename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
                        targetParent = dataContext.nodeRelationships.find(item => item._id === targetData.data._id)
                    }

                    let counter = 1;
                    scriptParent.relationships.forEach((rel) => {
                        if (rel.relatedTo === targetParent._id) {
                            counter++;
                        }
                    });

                    void addNewPropertyToScriptMutation({
                        variables: {
                            scriptID: scriptParent._id,
                            eventType: null,
                            name: defaultName,
                            displayName: defaultName,
                            relatedTo: targetParent._id
                        }
                    }).then(({ data, errors }: any) => {
                        if (errors) {
                            for (const e of errors) {
                                toastError(t(`validations:${e.message}`));
                            }

                            return;
                        }

                        void addD3Item({
                            type: RelationshipType.RELATION,
                            newItemData: {
                                curve: setCurve(counter),
                                source: scriptParent,
                                target: targetParent,
                                _id: data.addNewRelationshipToNiamScript._id,
                                fieldType: NiamFieldTypeEnum.Relationship,
                                isShown: true,
                                displayName: defaultName,
                                name: defaultName,
                                relatedTo: targetParent._id
                            }
                        });
                    });
                }

                if (sourceTypename === NodeTypename.NIAM_INTERFACE && targetTypename === NodeTypename.NIAM_OBJECT) {
                    let fieldsAlreadyExists = false;
                    const interParent = dataContext.interfaces.find(item => item._id === sourceData.data._id)
                    const objParent = dataContext.objects.find(item => item._id === targetData.data._id)

                    interParent.fields.forEach(item => {
                        objParent.interfaces.forEach(inter => {
                            if (interParent._id !== inter._id) {
                                const interCurrent = dataContext.interfaces.find(f => f._id === inter._id)
                                const alreadyExists = interCurrent.fields.find(f => f.name === item.name);

                                if (alreadyExists) {
                                    fieldsAlreadyExists = true
                                }
                            }
                        })

                        const alreadyExists = objParent.fields.find(f => f.name === item.name);

                        if (alreadyExists) {
                            fieldsAlreadyExists = true
                        }
                    })

                    if (fieldsAlreadyExists) {
                        toastWarning(t(`validations:interface.fields.already.exists`));
                        return
                    }

                    let counter = 1;

                    interParent.fields.forEach((rel) => {
                        if (rel.relatedTo === objParent._id) {
                            counter++;
                        }
                    });

                    void implementInterface({
                        variables: {
                            serverID: server._id,
                            niamInterfaceID: interParent._id,
                            niamObjectID: objParent._id
                        }
                    }).then(({ data, errors }: any) => {
                        if (errors) {
                            for (const e of errors) {
                                toastError(t(`validations:${e.message}`));
                            }

                            return;
                        }

                        void addD3Item({
                            type: RelationshipType.IMPLEMENT,
                            newItemData: {
                                curve: setCurve(counter),
                                source: objParent,
                                target: interParent,
                                type: RelationshipType.IMPLEMENT,
                                _id: `${interParent._id}${objParent._id}`,
                                isShown: true
                            }
                        });
                    });
                }

                if (sourceTypename === NodeTypename.NIAM_ENUM && targetTypename === NodeTypename.NIAM_OBJECT) {
                    const enumParent = dataContext.enums.find(item => item._id === sourceData.data._id)
                    const objParent = dataContext.objects.find(item => item._id === targetData.data._id)
                    const { defaultName, counter } = setDefaultName(objParent.name, enumParent.name, objParent.fields, enumParent);

                    const alreadyExists = objParent.fields.some(f => f.relatedTo === enumParent._id);

                    if (alreadyExists) {
                        toastWarning(t(`validations:enum.connected.to.object`));

                    } else {
                        void addNewPropertyToObjectMutation({
                            variables: {
                                NiamObjectID: objParent._id,
                                newNiamFieldInput: {
                                    fieldType: NiamFieldTypeEnum.Enum,
                                    name: defaultName,
                                    displayName: defaultName,
                                    isArray: false,
                                    isRequired: false,
                                    isNaming: false,
                                    isSecret: false,
                                    relatedTo: enumParent._id
                                }
                            }
                        }).then(({ data, errors }: any) => {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }

                                return;
                            }

                            void addD3Item({
                                type: RelationshipType.RELATION,
                                newItemData: {
                                    curve: setCurve(counter),
                                    source: enumParent,
                                    target: objParent,
                                    _id: data.addNewFieldToNiamObject._id,
                                    isShown: true,
                                    displayName: defaultName,
                                    name: defaultName,
                                    relatedTo: enumParent._id,
                                    fieldType: NiamFieldTypeEnum.NiamEnum,
                                    type: "enum"
                                }
                            });
                        });
                    }
                }

                if (sourceTypename === NodeTypename.NIAM_ENUM && targetTypename === NodeTypename.NIAM_INTERFACE) {
                    const enumParent = dataContext.enums.find(item => item._id === sourceData.data._id)
                    const interParent = dataContext.interfaces.find(item => item._id === targetData.data._id)
                    const { defaultName, counter } = setDefaultName(interParent.name, enumParent.name, interParent.fields, enumParent);

                    const alreadyExists = interParent.fields.some(f => f.relatedTo === enumParent._id);

                    if (alreadyExists) {
                        toastWarning(t(`validations:enum.connected.to.interface`));
                    } else {
                        void addNewPropertyToIntefaceMutation({
                            variables: {
                                niamInterfaceID: interParent._id,
                                newNiamFieldInput: {
                                    fieldType: NiamFieldTypeEnum.Enum,
                                    name: defaultName,
                                    displayName: defaultName,
                                    isArray: false,
                                    isRequired: false,
                                    isNaming: false,
                                    isSecret: false,
                                    relatedTo: enumParent._id
                                }
                            }
                        }).then(({ data, errors }: any) => {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }

                                return;
                            }

                            void addD3Item({
                                type: RelationshipType.RELATION,
                                newItemData: {
                                    curve: setCurve(counter),
                                    source: enumParent,
                                    target: interParent,
                                    _id: data.addNewNiamFieldToNiamInterface._id,
                                    fieldType: NiamFieldTypeEnum.NiamEnum,
                                    isShown: true,
                                    displayName: data.addNewNiamFieldToNiamInterface.displayName,
                                    name: data.addNewNiamFieldToNiamInterface.name,
                                    relatedTo: enumParent._id,
                                    type: "enum"
                                }
                            });
                        });
                    }
                }

                setItemSelected(null);
                setSourceData(undefined);
                setTargetData(undefined);
            }
        }


    }, [isDrawing]);
};
