// @flow
import { useData } from "contexts/dataContext";
import { useContext } from "react";
import { SourceData, TargetData } from "typedefs";
import
    {
        addNewNodeRelationship_addNewNodeRelationship as NewNodeRelationship,
        addNewNodeRelationship_addNewNodeRelationship_rel_fields as NewNodeRelationshipField
    } from "types/operation-result-types";
import { CanvasContext } from "../../../contexts/canvasContext";
import { RelationshipType, iData } from "../types";
import { addD3Link } from "./link";
import { addD3Orbit } from "./orbit";

export type EAddType =
    | "customMutation"
    | "enum"
    | "implement"
    | "paths"
    | "interface"
    | "nodeRelation"
    | "nodeRelationship"
    | "object"
    | "relation"
    | "script"
    | "tag";

interface UseAddD3ItemsParams {
    injectData?: Partial<iData & NewNodeRelationship>;
    newItemData: Partial<{
        curve: number;
        source: SourceData | string | unknown;
        target: TargetData | string | unknown;
        _id: string;
        isShown: boolean;
        isAction: boolean;
        isVC: boolean;
        simplePath?: any;
        simplePathCreate?: any;
        simplePathRead?: any;
        simplePathUpdate?: any;
        simplePathDelete?: any;
        displayName: string;
        nodeRelationships: NewNodeRelationshipField[];
        name: string;
        relatedTo: string;
        fields: NewNodeRelationshipField[];
        type: string;
        eventType?: string;
        x: number;
        y: number;
        fx?: number;
        fy?: number;
        vx: number;
        vy: number;
        item?: unknown;
        index?: unknown;
        fieldType?: any;
        __typename?: any;
    }>;
    type: EAddType;
}

export const useAddD3Items = (): (({ type, newItemData, injectData }: UseAddD3ItemsParams) => Promise<iData>) => {
    const { d3Data, data, setData, enters, container } = useContext(CanvasContext);
    const { setZoom, zoom, setServer, server, initialCenterX, initialCenterY } = useData();

    return async ({ type, newItemData, injectData }: UseAddD3ItemsParams): Promise<iData> => {
        let newData;

        switch (type) {
            case "object":
            case "script":
            case "customMutation":
            case "tag":
            case "enum":
            case "interface":
            case "nodeRelationship":

                // adds positioning to orbits only
                newItemData = {
                    ...newItemData,
                    fx: newItemData.x,
                    fy: newItemData.y,
                };

                newData = addD3Orbit({
                    injectData,
                    d3Data,
                    data,
                    type,
                    enters,
                    newItemData,
                    container,
                    setZoom,
                    zoom,
                    initialCenterX,
                    initialCenterY
                });
                break;

            case RelationshipType.PATHS:
            case RelationshipType.IMPLEMENT:
            case RelationshipType.RELATION:
            case RelationshipType.NODE_RELATION:

                newData = addD3Link({
                    d3Data,
                    data: injectData ? injectData : data,
                    type,
                    enters,
                    newItemData
                });

                break;
        }


        const newDataCollection = { ...server }
        newDataCollection.niamCustomMutations = [...newData.customMutations]
        newDataCollection.niamTags = [...newData.tags]
        newDataCollection.niamObjects = [...newData.objects]
        newDataCollection.niamInterfaces = [...newData.interfaces]
        newDataCollection.niamScripts = [...newData.scripts]
        newDataCollection.niamEnums = [...newData.enums]
        newDataCollection.niamNodeRelationships = [...newData.nodeRelationships]
        newDataCollection.primPropertiesRelLinks = [...newData.relLinks]
        setServer(newDataCollection);

        setData(newData);
        return newData
    };
};
